import 'aos/dist/aos.css';
import AOS from 'aos';

AOS.init({
    // offset: 200,
    // easing: 'ease-in-out',
    once: false,
});

// svg error
const error = console.error;
console.error = (...args) => {
    if (/defaultProps/.test(args[0])) return;
    error(...args);
}