exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-business-index-jsx": () => import("./../../../src/pages/business/index.jsx" /* webpackChunkName: "component---src-pages-business-index-jsx" */),
  "component---src-pages-faq-index-jsx": () => import("./../../../src/pages/faq/index.jsx" /* webpackChunkName: "component---src-pages-faq-index-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-news-[id]-jsx": () => import("./../../../src/pages/news/[id].jsx" /* webpackChunkName: "component---src-pages-news-[id]-jsx" */),
  "component---src-pages-news-announcement-[id]-jsx": () => import("./../../../src/pages/news/announcement/[id].jsx" /* webpackChunkName: "component---src-pages-news-announcement-[id]-jsx" */),
  "component---src-pages-news-announcement-index-jsx": () => import("./../../../src/pages/news/announcement/index.jsx" /* webpackChunkName: "component---src-pages-news-announcement-index-jsx" */),
  "component---src-pages-news-cooperation-[id]-jsx": () => import("./../../../src/pages/news/cooperation/[id].jsx" /* webpackChunkName: "component---src-pages-news-cooperation-[id]-jsx" */),
  "component---src-pages-news-cooperation-index-jsx": () => import("./../../../src/pages/news/cooperation/index.jsx" /* webpackChunkName: "component---src-pages-news-cooperation-index-jsx" */),
  "component---src-pages-news-index-jsx": () => import("./../../../src/pages/news/index.jsx" /* webpackChunkName: "component---src-pages-news-index-jsx" */),
  "component---src-pages-parking-index-jsx": () => import("./../../../src/pages/parking/index.jsx" /* webpackChunkName: "component---src-pages-parking-index-jsx" */),
  "component---src-pages-policy-index-jsx": () => import("./../../../src/pages/policy/index.jsx" /* webpackChunkName: "component---src-pages-policy-index-jsx" */),
  "component---src-pages-policy-privacy-jsx": () => import("./../../../src/pages/policy/privacy.jsx" /* webpackChunkName: "component---src-pages-policy-privacy-jsx" */),
  "component---src-pages-policy-protocol-index-jsx": () => import("./../../../src/pages/policy/protocol/index.jsx" /* webpackChunkName: "component---src-pages-policy-protocol-index-jsx" */),
  "component---src-pages-policy-protocol-irregularities-jsx": () => import("./../../../src/pages/policy/protocol/irregularities.jsx" /* webpackChunkName: "component---src-pages-policy-protocol-irregularities-jsx" */),
  "component---src-pages-policy-protocol-prohibited-jsx": () => import("./../../../src/pages/policy/protocol/prohibited.jsx" /* webpackChunkName: "component---src-pages-policy-protocol-prohibited-jsx" */),
  "component---src-pages-policy-protocol-restricted-jsx": () => import("./../../../src/pages/policy/protocol/restricted.jsx" /* webpackChunkName: "component---src-pages-policy-protocol-restricted-jsx" */),
  "component---src-pages-store-case-jsx": () => import("./../../../src/pages/store/case.jsx" /* webpackChunkName: "component---src-pages-store-case-jsx" */),
  "component---src-pages-store-index-jsx": () => import("./../../../src/pages/store/index.jsx" /* webpackChunkName: "component---src-pages-store-index-jsx" */),
  "component---src-pages-store-mode-jsx": () => import("./../../../src/pages/store/mode.jsx" /* webpackChunkName: "component---src-pages-store-mode-jsx" */)
}

